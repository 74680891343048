import jquery, { event } from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "./scss/index.scss";
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import simpleParallax from "simple-parallax-js";


//#region navbar sticky
const stickybar = document.querySelector(".navbar-main");
let stickybarTop = stickybar.offsetTop;
window.addEventListener("scroll", () => {
    if (window.scrollY > stickybarTop) {
        stickybar.classList.add("navbar-main--sticky");
    } else {
        stickybar.classList.remove("navbar-main--sticky");
    }
});
//#endregion
//#region offcavas mobile
$("[data-trigger]").on("click", function () {
    var trigger_id = $(this).attr("data-trigger");
    $(trigger_id).toggleClass("show");
    $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
    $(".navbar-collapse").removeClass("show");
    $("body").removeClass("offcanvas-active");
});
//#endregion
//#region sliders
$(".sliderhome").slick({
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    arrows: false,
    speed: 600,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: false,
                adaptiveHeight: false,
                autoplay: true,
                autoplaySpeed: 3000,
            },
        },
    ],
});
$(".sliderhome-mobile").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    asNavFor: ".sliderhome-mobile-nav",
});
$(".sliderhome-mobile-nav").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: ".sliderhome-mobile",
    arrows: false,
    dots: false,
    centerMode: false,
    focusOnSelect: true,
});
$(".slider-icon-home").slick({
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: false,
    arrows: false,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
                adaptiveHeight: true,
                centerPadding: "50%",
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
                adaptiveHeight: true,
                centerPadding: "50%",
            },
        },
    ],
});
$(".review-section-slider").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
            },
        },
    ],
});
$(".rassegna-section-slider").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    dots: false,
    arrows: true,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
            },
        },
    ],
});
$(".news-card-slider").slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    arrows: true,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: false,
            },
        },
    ],
});
$('.product-slider__main-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    asNavFor: '.product-slider__nav-slider',
    arrows: false
});
$('.product-slider__nav-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.product-slider__main-slider',
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    arrows: false,
});
$(".slider-gadget").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    dots: false,
    arrows: true,
    centerPadding: '70px',
    responsive: [
        {
            breakpoint: 1450,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                infinite: true,
                dots: true,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: false,
            },
        },
    ],
});
$(".slider-cross-selling").slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 4,
    dots: false,
    arrows: true,
    centerPadding: '70px',
    responsive: [
        {
            breakpoint: 1450,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                infinite: true,
                dots: true,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: false,
            },
        },
    ],
});
//#endregion
//#region input number
jQuery(
    '<div class="quantity-nav"><div class="quantity-button quantity-up"><img src="./images/spinner-up.svg"></div><div class="quantity-button quantity-down"><img src="./images/spinner-up.svg"></div></div>'
).insertAfter(".quantity input");
jQuery(".quantity").each(function () {
    var spinner = jQuery(this),
        input = spinner.find('input[type="number"]'),
        btnUp = spinner.find(".quantity-up"),
        btnDown = spinner.find(".quantity-down"),
        min = input.attr("min"),
        max = input.attr("max");

    btnUp.on("click", function () {
        var oldValue = parseFloat(input.val());
        if (oldValue >= max) {
            var newVal = oldValue;
        } else {
            var newVal = oldValue + 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
    });

    btnDown.on("click", function () {
        var oldValue = parseFloat(input.val());
        if (oldValue <= min) {
            var newVal = oldValue;
        } else {
            var newVal = oldValue - 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
    });
});
//#endregion
//#region counter animation
const counterAnim = (qSelector, start = 0, end, duration = 1000) => {
    const target = document.querySelector(qSelector);
    let startTimestamp = null;
    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        target.innerText = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
};
document.addEventListener("DOMContentLoaded", () => {
    counterAnim("#count", 10, 300, 1000);
});
//#endregion
//#region parallax
var image = document.getElementsByClassName("video-background__container");
var video = document.getElementsByClassName("parallax__img");
new simpleParallax(image, {
    delay: 0.1,
    transition: "cubic-bezier(0,0,0,1)",
    scale: 1.6,
});
new simpleParallax(video, {
    delay: 0.1,
    transition: "cubic-bezier(0,0,0,1)",
    scale: 1.6,
});
//#endregion
//#region toggle fatturazione
$('.check-fatturazione').on('click', function () {
    if ($(this).prop('checked')) {
        $(this).parents('.recap-checkout-info').find('.fatturazione-diversa').fadeIn('slow');
    } else
        $(this).parents('.recap-checkout-info').find('.fatturazione-diversa').fadeOut('slow');
});
//#endregion
//#region close topbar mobile
$(".close-topbar").on('click', function () {
    $(".topbar").fadeOut('fast');
});
//#endregion
//#region show password
$(".show-pw-group a").on('click', function (event) {
    event.preventDefault();
    if ($('.show-pw-group input').attr("type") == "text") {
        $('.show-pw-group input').attr('type', 'password');
        $('.show-pw-group i').addClass("fa-eye");
        $('.show-pw-group i').removeClass("fa-eye-slash");
    } else if ($('.show-pw-group input').attr("type") == "password") {
        $('.show-pw-group input').attr('type', 'text');
        $('.show-pw-group i').removeClass("fa-eye");
        $('.show-pw-group i').addClass("fa-eye-slash");
    }
});

//#endregion
//#region read more
$('.link-more').on("click", function () {
    $('.read-more').slideToggle();
    if ($('.link-more').text() == "Leggi di più") {
        $(this).text("Leggi di meno")
    } else {
        $(this).text("Leggi di più")
    }
});
//#endregion

//#region countdown
//(function () {
//    const second = 1000,
//        minute = second * 60,
//        hour = minute * 60,
//        day = hour * 24;

//    //I'm adding this section so I don't have to keep updating this pen every year :-)
//    //remove this if you don't need it
//    let today = new Date(),
//        dd = String(today.getDate()).padStart(2, "0"),
//        mm = String(today.getMonth() + 1).padStart(2, "0"),
//        yyyy = today.getFullYear(),
//        nextYear = yyyy + 1,
//        dayMonth = "02/17/",
//        birthday = dayMonth + yyyy;

//    today = mm + "/" + dd + "/" + yyyy;
//    if (today > birthday) {
//        birthday = dayMonth + nextYear;
//    }
//    //end

//    const countDown = new Date(birthday).getTime(),
//        x = setInterval(function () {

//            const now = new Date().getTime(),
//                distance = countDown - now;

//            document.getElementById("days").innerText = Math.floor(distance / (day)),
//                document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour)),
//                document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute)),
//                document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);

//            //do something later when date is reached
//            if (distance < 0) {
//                document.getElementById("countdown-headline").innerText = "Buon Compleanno!";
//                document.getElementById("countdown").style.display = "none";
//                document.getElementById("countdown-content").style.display = "block";
//                clearInterval(x);
//            }
//            //seconds
//        }, 0)
//}());
  //#endregion
